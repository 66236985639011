<template>
  <v-container class="is-home">
    <v-row>
      <v-col cols="12" class="pl-0 pr-0 pt-0">
        <div class="main-notif-box">
          <p v-show="this.notifications.length === 0" class="text-center">
            <strong>You don't have notifications</strong>
          </p>
          <v-card v-for="element in getNotificationsList()" :key="element.file_id" class="mt-2"
            :class="getAlertColorClass(element.status_id)">
            <v-card-text>
              <div class="text--primary">
                <div v-if="element.urgent==true">
                  <v-badge color="error" :content="'Urgent'" class="urgent-badge"></v-badge></div>
                <span v-show="element.status_id == Constants.STATUS_REJECTED_ID">
                  <strong>FILE REJECTED</strong> on {{ getDate(element.last_historic_doc_date_timestamp) }}
                </span>
                <span v-show="element.status_id == Constants.STATUS_PENDING_REVIEW_ID">
                  <strong>NEW FILE TO APPROVE</strong> on {{ getDate(element.last_historic_doc_date_timestamp) }}
                </span>
                <div>
                  <span><strong>Title</strong>:</span>
                  <router-link id="new-title-router-notification-card" :to="`/library/edit/${element.file_id}/`">
                    {{ element.title }}
                  </router-link>
                </div>
                <div>
                  <span><strong>Last modifier</strong>: {{ element.last_historic_doc_last_user_email }}</span>
                </div>
                <div><span><strong>Commentary</strong>: {{ element.commentary }}</span></div>
              </div>
            </v-card-text>
          </v-card>
          <div v-if="notifications.length > 5" class="view-more text-center mt-5">
            <router-link :to="'/inbox'">Ver más</router-link>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { fileServices } from '@/services/fileServices';
import { Constants } from '@/store/constants';

export default {
  name: 'HomeNotifications',
  data() {
    return {
      Constants,
      notifications: [],
      files: [],
    };
  },
  props: {
    actualSearch: String,
  },
  created() {
    this.getInboxData();
  },
  methods: {
    getInboxData() {
      fileServices.getInboxData()
        .then((response) => { this.notifications = response.data; });
    },
    getAlertColorClass(statusId) {
      if (statusId === Constants.STATUS_PENDING_REVIEW_ID) {
        return 'border-pending-review';
      }
      if (statusId === Constants.STATUS_REJECTED_ID) {
        return 'border-rejected';
      }
      return '';
    },
    getDate(d) {
      return new Date(d)
        .toLocaleString('es-CL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
    },
    getNotificationsList() {
      return this.notifications.slice(0, 5);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card {
  border-radius: 8px !important;
  &.border-pending-review::before {
    border-left: 3px solid #2AB3BA;
    content: ' ';
    top: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
  }
  &.border-rejected::before {
    border-left: 3px solid #cc2020;
    content: ' ';
    top: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
  }
}
.is-home {
  .v-alert {
    padding: 7px 15px;
  }
  .not-approved-span-notification-card,
  .new-title-span-notification-card,
  .new-modifier-span-notification-card,
  .new-commentary-span-notification-card {
    font-size: 14px;
  }
}
</style>
